import React from 'react'
import { Link } from 'gatsby'
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import { Layout } from '../components/common'

import Profile from '../images/profile.jpeg'

const Sobre = ({ intl }) => (
    <>
        <Layout title="Sobre - Lupercio Manoel e Souza" meta="Conheça mais sobre o decorador e artísta plástico Lupercio">
            <div className="container">
                <h1>{intl.formatMessage({ id: "sobre" })}</h1>
                <div className="p-4">
                    <div className="float-right text-center"><br/><img className="rounded-md m-2 max-h-screen ml-10" style={{maxHeight: `330px`}} src={Profile} /><span className="text-xl"><b>{intl.formatMessage({ id: "foto" })}: Daniel Katz</b></span></div>
                    <h3><div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "sobrePaginaTexto" })}}></div></h3>                    
                </div>
            </div>
            <br/><br/>
        </Layout>
    </>
)

export default injectIntl(Sobre)


